import * as React from 'react'
import cn from 'classnames'
import { useMegaModal } from './root'

export type BodyProps = {
  children: React.ReactNode
  className?: string
  disableRightPane?: boolean
}

export const Body: React.VFC<BodyProps> = (props) => {
  const { children, className, disableRightPane } = props
  const { rightPaneOpen } = useMegaModal()

  // TODO: This is a hack to enable nesting rails modals inside the mega modal.
  // Remove this once we have a better modal system in place.
  React.useEffect(() => {
    window.closeModal()

    const railsModalAnchor = document.querySelector('body > #modal-anchor')
    if (!railsModalAnchor) return
    railsModalAnchor.id = 'modal-anchor-disabled'
    document.body.classList.add('mega-modal-open')

    return () => {
      railsModalAnchor.id = 'modal-anchor'
      document.body.classList.remove('mega-modal-open')
    }
  }, [])

  return (
    <>
      <div
        className={cn(
          'flex flex-col overflow-y-auto pt-20 transition-[padding] h-full gap-6 md:gap-8',
          className,
          rightPaneOpen && !disableRightPane && 'lg:pr-[380px]'
        )}
      >
        {children}
      </div>
      {/* Place Rails modal anchors inside the mega modal for nesting */}
      <div id="modal-anchor" />
    </>
  )
}
