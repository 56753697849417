import * as React from 'react'

export type ScrollState = { top: boolean; bottom: boolean }
export type UseScrollProps = { margin?: number; deps?: React.DependencyList }

/**
 * Hook which provides the state of a scroll on a scrollable element,
 * specifically whether the scroll container is at its top or bottom
 *
 * @param options.margin - add a buffer from the top or bottom of the container (in px)
 * @param options.deps - dependency array to trigger a recalculation of the scroll state
 * @returns A ref to apply to the container, and the current scroll state
 */
export const useScroll = <El extends HTMLElement>({
  margin = 20,
  deps = [],
}: UseScrollProps = {}) => {
  const ref = React.useRef<El>(null)
  const [top, setTop] = React.useState(false)
  const [bottom, setBottom] = React.useState(false)

  React.useEffect(() => {
    if (!ref.current) return
    const el = ref.current

    const handler = () => {
      setTop(el.scrollTop < margin)
      setBottom(el.scrollTop + el.clientHeight > el.scrollHeight - margin)
    }

    handler()
    el.addEventListener('scroll', handler)

    return () => el.removeEventListener('scroll', handler)
  }, deps)

  const scrollState: ScrollState = { top, bottom }
  return { ref, scrollState }
}
