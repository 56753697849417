import * as React from 'react'
import cn from 'classnames'

export type ContentProps = {
  children: React.ReactNode
  className?: string
}

export const Content: React.VFC<ContentProps> = (props) => {
  const { children, className } = props

  return (
    <div className="flex flex-col items-center px-4 md:px-16 xl:px-auto">
      <div className={cn('break-words w-full md:max-w-[628px]', className)}>
        {children}
      </div>
    </div>
  )
}
