import { observer } from 'mobx-react-lite'
import { TabbedContent } from 'src/design-system'
import * as React from 'react'
import cn from 'classnames'
import { CommentList } from 'components/comments/comment-drawer/comment-list'
import { HistorySection } from 'components/history-section'
import { Position } from 'store/modules/positions'
import { SkillVariant } from 'store/modules/skill-variants'
import { store } from 'store/index'
import { trackEvent } from '../../services/event-tracker'

export type TabbedInfoSectionProps = {
  resource: Position | SkillVariant
}

export type Tab = 'comments' | 'history' | 'about'
export const TabbedInfoSection = observer<TabbedInfoSectionProps>((props) => {
  const { resource } = props

  const [activeTab, setActiveTab] = React.useState<Tab>('comments')

  const onClick = (tabName: Tab) => {
    setActiveTab(tabName)

    trackEvent('$track_modal_tab_clicked', {
      modal: resource._type.name === 'positions' ? 'position' : 'skill',
      tab: tabName,
    })
  }

  const parentComments = store.comments.sortedParentComments(resource.comments)

  return (
    <TabbedContent.Root id="tabbed-info-section" initialTabId="comments">
      <div className="mb-4 flex">
        <TabbedContent.Tabs className="gap-1 overflow-y-auto" variant="pill">
          <TabbedContent.Tab
            className="py-1.5 group"
            id="comments"
            key="comments"
            onClick={() => onClick('comments')}
          >
            Comments
            <span className="opacity-50 ml-1">
              {store.comments.openCommentCount(parentComments)}
            </span>
          </TabbedContent.Tab>
          <TabbedContent.Tab
            id="history"
            key="history"
            onClick={() => onClick('history')}
          >
            History
          </TabbedContent.Tab>
        </TabbedContent.Tabs>
      </div>
      <TabbedContent.Content tabId="comments" key="comments">
        <CommentList
          commentable={resource}
          comments={parentComments}
          privacyNotice="Visible to editors"
        />
      </TabbedContent.Content>
      <TabbedContent.Content tabId="history" key="history">
        <HistorySection resource={resource} />
      </TabbedContent.Content>
    </TabbedContent.Root>
  )
})
