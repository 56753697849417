import { Button } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ChangeItemList } from 'components/change-item-list'
import { LoadingContent } from 'components/loading-content'
import { Position } from 'store/modules/positions'
import { SkillVariant } from 'store/modules/skill-variants'
import { store } from 'store/index'

export type HistorySectionProps = {
  resource: Position | SkillVariant
}

export const HistorySection = observer<HistorySectionProps>((props) => {
  const { resource } = props

  const [hasNextPage, setHasNextPage] = React.useState(false)
  const [pageNumber, setPageNumber] = React.useState(1)

  React.useEffect(() => {
    const fetchChanges = async () => {
      const resourceType =
        resource._type.name === 'positions' ? 'Position' : 'SkillVariant'

      const { meta } = await store.changes.fetchAll({
        filter: { resource_id: resource.id, resource_type: resourceType },
        include: ['resource', 'resource.skill', 'user'],
        page: { number: pageNumber, size: 20 },
      })

      const hasNextPage = meta?.pages && meta.pages > pageNumber
      setHasNextPage(!!hasNextPage)
    }

    fetchChanges()
  }, [pageNumber, resource])

  return (
    <LoadingContent
      className="pt-10"
      loading={store.changes.loading && pageNumber === 1}
    >
      <div className="flex flex-col gap-4 relative">
        <div className="absolute bg-gray-100 h-full left-[9px] w-px" />
        {store.changes.forResource(resource).map((change) => (
          <ChangeItemList change={change} key={change.id} resource={resource} />
        ))}
      </div>
      {hasNextPage && (
        <Button
          className="mt-5 mx-auto"
          colourVariant="theme"
          onClick={() => setPageNumber(pageNumber + 1)}
          size="default"
          variant="outline"
        >
          Load more
        </Button>
      )}
    </LoadingContent>
  )
})
