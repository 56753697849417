import toTitleCase from 'to-title-case'
import { ChangeItemListVm } from './change-item-list-vm'
import { ChangeOperation } from './change-item-list'
import { Requirement } from 'store/modules/requirements'
import { store } from 'store/index'

const POSITION_ATTRIBUTES = [
  'description',
  'hiring_link',
  'name',
  'seniority_level',
]

export class PositionChangeItemListVm extends ChangeItemListVm {
  get operations() {
    return this.renderIndividualChanges
      ? this.change.operations.filter((operation) =>
          POSITION_ATTRIBUTES.includes(operation.attribute)
        )
      : this.change.operations
  }

  get renderIndividualChanges() {
    return (
      this.change.resourceType === 'Position' && this.change.type === 'update'
    )
  }

  text(operation?: ChangeOperation) {
    switch (this.change.resourceType) {
      case 'Position':
        return this.positionText(operation)
      case 'Requirement':
        return this.requirementText
      default:
        return ''
    }
  }

  private get requirementCreateChange() {
    return store.changes.forResourceByType(
      this.change.resourceId,
      'Requirement',
      'create'
    )[0]
  }

  private get requirementText() {
    switch (this.change.type) {
      case 'create':
        return ` added the skill ${this.skillName}, level ${this.level()}`
      case 'destroy':
        return ` removed the skill ${
          this.skillName
        }, level ${this.previousLevel()}`
      case 'update':
        return this.discardedAtOperation
          ? ` removed the skill ${this.skillName}, level ${this.previousLevel(
              this.requirementCreateChange
            )}`
          : ` updated the level on the skill ${
              this.skillName
            } from ${this.previousLevel()} to ${this.level()}`
    }
  }

  private get skill() {
    if (this.change.resourceType !== 'Requirement') return

    const requirement = this.change.resource as Requirement
    if (requirement?.skill) return requirement.skill

    const skillId =
      this.findOperation('skill_id', this.change.operations)?.previousValue ||
      this.findOperation('skill_id', this.change.operations)?.value ||
      (this.requirementCreateChange &&
        this.findOperation('skill_id', this.requirementCreateChange.operations)
          ?.value)

    if (!skillId) return

    return store.skills.byId(skillId)
  }

  private get skillName() {
    return this.skill?.name || '[deleted]'
  }

  private positionText(operation?: ChangeOperation) {
    switch (this.change.type) {
      case 'create':
        return ' created this Position'
      case 'destroy':
        return ' removed this Position'
      case 'update':
        return this.positionUpdateText(operation)
    }
  }

  private positionUpdateText(operation?: ChangeOperation) {
    if (!operation) return ''

    const attribute = toTitleCase(operation.attribute).toLowerCase()
    const previousValue = operation.previousValue
    const value = operation.value

    if (previousValue && value) {
      return ` changed the ${attribute} from "${previousValue}" to "${value}"`
    } else if (previousValue) {
      return ` removed the ${attribute} "${previousValue}"`
    } else {
      return ` added a ${attribute} "${value}"`
    }
  }
}
