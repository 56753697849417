import cn from 'classnames'
import { AnimatePresence, AnimateSharedLayout } from 'framer-motion'
import * as React from 'react'
import { useScroll } from 'src/design-system'
import { CommentForm, CommentFormProps } from '../comment-form'
import { CommentThread } from '../comment-thread'
import { Position } from 'store/modules/positions'
import { Comment } from 'store/modules/comments'
import { SkillVariant } from 'store/modules/skill-variants'

type CommentListProps = {
  /**
   * Title of the comment Drawer
   * @defaultValue Comments
   */
  title?: string
  /**
   * Text to display in comment form's privacy notice
   * @see CommentForm
   */
  privacyNotice: CommentFormProps['privacyNotice']
  commentable: Position | SkillVariant
  comments: Comment[]
}

export const CommentList: React.VFC<CommentListProps> = ({
  comments,
  privacyNotice,
  commentable,
}) => {
  const [showResolved, setShowResolved] = React.useState(false)
  const openComments = React.useMemo(
    () => comments.filter((c) => !c.threadResolved),
    [comments]
  )
  const resolvedComments = React.useMemo(
    () => comments.filter((c) => c.threadResolved),
    [comments]
  )
  const resolvedCount = comments.length - openComments.length
  const [replyFormId, setReplyFormId] = React.useState<string | null>(null)

  const { ref, scrollState } = useScroll<HTMLDivElement>({
    deps: [openComments],
  })

  return (
    <>
      <CommentForm
        className={cn('mx-2 border-b border-transparent transition-colors', {
          'border-gray-100': !scrollState.top,
        })}
        commentable={commentable}
        privacyNotice={privacyNotice}
      />

      <AnimateSharedLayout>
        <div className="overflow-auto flex-grow pt-2" ref={ref}>
          {openComments.map((comment) => (
            <div key={comment.id}>
              <CommentThread
                comment={comment}
                onSubmit={async () => {
                  setReplyFormId(null)
                }}
                privacyNotice={privacyNotice}
                onClickReply={() => {
                  setReplyFormId(comment.id)
                }}
                showReplyForm={comment.id === replyFormId}
                onCancelReply={() => setReplyFormId(null)}
              />
            </div>
          ))}
        </div>
      </AnimateSharedLayout>
      <AnimatePresence>
        {resolvedCount > 0 && (
          <div className="flex flex-1 justify-center transition-colors">
            <button onClick={() => setShowResolved(!showResolved)}>
              <span className="text-theme-40 hover:text-theme-30">
                {showResolved ? 'Hide' : 'Show'} resolved comments
              </span>
            </button>
          </div>
        )}
        {showResolved && resolvedCount > 0 && (
          <div className="overflow-auto flex-grow" ref={ref} key="resolved">
            <hr className="bg-gray-100 w-full my-4 border-0 h-px" />
            {resolvedComments.map((comment) => (
              <CommentThread
                key={comment.id}
                comment={comment}
                onSubmit={async () => {
                  setReplyFormId(null)
                }}
                privacyNotice={privacyNotice}
                onClickReply={() => {
                  setReplyFormId(comment.id)
                }}
                showReplyForm={comment.id === replyFormId}
                onCancelReply={() => setReplyFormId(null)}
              />
            ))}
          </div>
        )}
      </AnimatePresence>
    </>
  )
}
