import { AnimatePresence, motion } from 'framer-motion'
import * as React from 'react'
import cn from 'classnames'
import { Comment as CommentModel } from 'store/modules/comments'
import { HtmlContent } from 'components/atoms/editor/html-content'
import { OverflowMenu, AvatarButton } from 'src/design-system'
import { CommentForm } from '../comment-form'
import { CheckCircle } from '@phosphor-icons/react'
import { CommentVm } from './comment-vm'
import { store } from 'store/index'

export type CommentProps = {
  /**
   * The comment object to display
   */
  comment: CommentModel
  /**
   * Text to display in the privacy notice
   */
  privacyNotice?: React.ReactNode
  /**
   * Hook called when the user clicks reply button
   */
  onClickReply?(): void
  /**
   * Flag whether or not to show the reply button
   */
  showReply?: boolean
  /**
   * Flag whether or not the comment is part of a resolved thread
   */
  className?: string
}

export const Comment: React.VFC<CommentProps> = ({
  comment,
  privacyNotice,
  onClickReply,
  showReply,
}) => {
  const { currentUser } = store
  const showEdit = comment.user.id === currentUser?.id
  const showDelete = comment.user.id === currentUser?.id || currentUser?.isAdmin

  const [editMode, setEditMode] = React.useState<boolean>(false)

  const vm = React.useMemo(() => {
    return new CommentVm(comment)
  }, [comment])

  if (!comment.content) return null

  return (
    <motion.article
      layout="position"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.2 }}
    >
      <div
        className={cn(
          'flex flex-col gap-x-2 border-0 border-l border-solid border-gray-100',
          comment.topLevel ? 'ml-2.5' : 'ml-9',
          {
            '!border-transparent': vm.endOfThread,
          }
        )}
      >
        <div className="flex justify-between items-center relative">
          <div className="-ml-2.5 mr-2 relative">
            <div
              className={cn({
                'left-[-17px] absolute top-0 h-3 w-8 border-0 border-l border-b rounded-tl-none border-solid border-gray-100 rounded-full':
                  comment.parent?.repliesWithContent[0] === comment,
              })}
            />
            <AvatarButton
              fallbackText={comment.user.initials}
              alt={comment.user.fullName}
              src={comment.user.avatarUrl}
              href={comment.user.profileUrl}
              size="xs"
              isInactive={comment.user.state !== 'active'}
            />
          </div>
          <p className="text-gray-600 text-sm mr-auto transition-colors mb-0">
            {comment.user.fullName}
          </p>
          {comment.topLevel && (
            <button
              className="p-2 hover:bg-gray-50 transition-colors rounded leading-4 absolute right-0 -top-2"
              title={
                comment.threadResolved ? 'Reopen comment' : 'Resolve comment'
              }
              onClick={() => comment.resolve()}
            >
              <CheckCircle
                className={cn(
                  'w-4 h-4',
                  comment.threadResolved ? 'text-gray-500' : 'text-theme-40'
                )}
                aria-hidden
                weight={comment.threadResolved ? 'fill' : 'bold'}
              />
            </button>
          )}
        </div>
        {editMode && showEdit && comment.commentable && (
          <CommentForm
            commentable={comment.commentable}
            comment={comment}
            className="mt-4 mr-2 mb-3 ml-7"
            privacyNotice={privacyNotice}
            onCancel={() => setEditMode(false)}
            submitLabel="Save"
            onSubmit={async () => {
              setEditMode(false)
            }}
          />
        )}
        <div className="pb-4">
          {(!editMode || !showEdit) && (
            <div className="ml-4 pl-0.5">
              <HtmlContent className="my-3 transition-colors">
                {comment.content}
              </HtmlContent>
              <div className="flex flex-row w-full justify-between">
                <div>
                  <time
                    dateTime={comment.createdAt.toISOString()}
                    title={comment.createdAt.toLocaleString()}
                    className="text-gray-600 text-xs mb-1 capitalize transition-colors"
                  >
                    {comment.relativeDate}
                  </time>
                  {showReply && (
                    <>
                      <span
                        className="text-gray-600 text-xs mb-1 capitalize transition-colors mx-1.5"
                        aria-hidden
                      >
                        &middot;
                      </span>
                      <button
                        type="button"
                        onClick={onClickReply}
                        className="text-xs text-theme-40 hover:text-theme-30"
                      >
                        Reply
                      </button>
                    </>
                  )}
                </div>
                {(showDelete || showEdit) && (
                  <OverflowMenu.Root right className="mr-1.5">
                    {showEdit && (
                      <OverflowMenu.Item onClick={() => setEditMode(!editMode)}>
                        {editMode ? 'Cancel' : 'Edit'}
                      </OverflowMenu.Item>
                    )}
                    {showDelete && (
                      <OverflowMenu.Item
                        variant="destructive"
                        onClick={() => vm.onDelete()}
                      >
                        Delete
                      </OverflowMenu.Item>
                    )}
                  </OverflowMenu.Root>
                )}
              </div>
            </div>
          )}
          <AnimatePresence>
            {comment.threadResolved && (
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                layout
                className="text-gray-600 text-xs mt-1 ml-4 pl-0.5"
              >
                Resolved by {comment.lastReply?.user.fullName}{' '}
                {comment.lastReply?.relativeDate}
              </motion.p>
            )}
          </AnimatePresence>
        </div>
      </div>
    </motion.article>
  )
}
