import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Change } from 'store/modules/changes'
import { Position } from 'store/modules/positions'
import { PositionChangeItemListVm } from './position-change-item-list-vm'
import { SkillVariant } from 'store/modules/skill-variants'
import { SkillVariantChangeItemListVm } from './skill-variant-change-item-list-vm'
import * as ChangeItem from 'components/change-item-list/change-item'

export type ChangeOperation = {
  attribute: string
  previousValue: string | null
  value: string | null
}

type ChangeItemListProps = {
  change: Change
  resource: Position | SkillVariant
}

export const ChangeItemList = observer((props: ChangeItemListProps) => {
  const { change, resource } = props

  const vm = React.useMemo(() => {
    switch (resource._type.name) {
      case 'positions':
        return new PositionChangeItemListVm(change)
      case 'skill_variants':
        return new SkillVariantChangeItemListVm(change)
    }
  }, [change, resource])

  if (!vm) return null

  return (
    <>
      {vm.renderIndividualChanges ? (
        vm.operations.length > 0 && (
          <div className="flex flex-col gap-4">
            {vm.operations.map((operation) => {
              return (
                <ChangeItem.Root change={change} key={operation.attribute}>
                  <ChangeItem.Body
                    text={vm.text(operation)}
                    user={change.user}
                  />
                </ChangeItem.Root>
              )
            })}
          </div>
        )
      ) : (
        <ChangeItem.Root change={change}>
          <ChangeItem.Body text={vm.text()} user={change.user} />
        </ChangeItem.Root>
      )}
    </>
  )
})
