import { Change } from 'store/modules/changes'
import { ChangeOperation } from './change-item-list'

export abstract class ChangeItemListVm {
  constructor(protected change: Change) {}

  get discardedAtOperation() {
    return (
      this.change.type === 'update' &&
      this.findOperation('discarded_at', this.change.operations)
    )
  }

  findOperation(attribute: string, operations: ChangeOperation[]) {
    return operations.find((operation) => operation.attribute === attribute)
  }

  level(createChange?: Change) {
    let operation = this.findOperation('level', this.change.operations)

    if (!operation && createChange?.operations) {
      operation = this.findOperation('level', createChange.operations)
    }

    return operation?.value
  }

  levelText(level?: string | null) {
    return level ? `level ${level}` : 'a level'
  }

  previousLevel(createChange?: Change) {
    if (this.discardedAtOperation) {
      const operations = createChange?.operations || []
      return this.findOperation('level', operations)?.value
    } else {
      return this.findOperation('level', this.change.operations)?.previousValue
    }
  }
}
