import * as React from 'react'
import { Comment as CommentModel } from 'store/modules/comments'
import { CommentForm } from '../comment-form'
import { Comment } from '../comment'

export type CommentThreadProps = {
  /**
   * The comment object to display
   */
  comment: CommentModel
  /**
   * Text to display in the privacy notice
   */
  privacyNotice?: React.ReactNode
  /** Hook called when a user submits the comment form
   * @see CommentForm
   */
  onSubmit?(): Promise<string | void>
  /**
   * Hook called when a user clicks the reply button
   */
  onClickReply?(): void
  /**
   * Whether the reply form is shown for the current thread
   */
  showReplyForm?: boolean
  /**
   * Hook called when the user hides the reply form
   */
  onCancelReply?(): void
}

export const CommentThread: React.VFC<CommentThreadProps> = ({
  comment,
  privacyNotice,
  showReplyForm,
  onClickReply,
  onCancelReply,
  onSubmit,
}) => {
  const replies = comment.replies?.filter((c: CommentModel) => c.content) ?? []
  if (!comment.commentable) return null

  return (
    <>
      <div>
        <Comment
          comment={comment}
          privacyNotice={privacyNotice}
          onClickReply={onClickReply}
          showReply={!replies.length}
        />
      </div>
      {replies.map((reply: CommentModel, index: number) => (
        <div key={reply.id}>
          <Comment
            comment={reply}
            onClickReply={onClickReply}
            showReply={index === replies.length - 1}
          />
        </div>
      ))}
      {showReplyForm && (
        <CommentForm
          commentable={comment.commentable}
          className="pl-4 pr-2"
          parentId={comment.id}
          onCancel={onCancelReply}
          submitLabel="Add reply"
          privacyNotice={privacyNotice}
          onSubmit={onSubmit}
        />
      )}
      <hr className="bg-gray-100 w-full m-0 mb-4 border-0 h-px" />
    </>
  )
}
