import * as React from 'react'
import cn from 'classnames'
import { Content } from './content'

export type HeaderProps = {
  children: React.ReactNode
  className?: string
}

export const Header: React.VFC<HeaderProps> = (props) => {
  const { children, className } = props

  return <Content className={cn(className)}>{children}</Content>
}
