import React, { FC } from 'react'

export type Breadcrumb = string | { label: string; url?: string }
export type BreadcrumbProps = {
  breadcrumbs: Breadcrumb[]
}

export const Breadcrumb: FC<BreadcrumbProps> = ({ breadcrumbs }) => {
  if (!breadcrumbs.length) return null
  const lastBreadcrumb = breadcrumbs.pop()
  return (
    <>
      <div className="hidden md:flex md:items-center">
        {breadcrumbs.map((breadcrumb, index) => (
          <p className="mb-0" key={index}>
            {typeof breadcrumb === 'string' ? (
              <span className="text-gray-900" key={index}>
                {breadcrumb}
              </span>
            ) : (
              <a
                href={breadcrumb.url}
                className="text-gray-900 hover:underline hover:text-theme-40"
                key={index}
              >
                {breadcrumb.label}
              </a>
            )}
            {index < breadcrumbs.length - 1 && (
              <span className="mx-1 text-gray-200">/</span>
            )}
          </p>
        ))}
      </div>
      <span className="md:hidden">...</span>
      <span className="mx-1 text-gray-200">/</span>
      {lastBreadcrumb}
    </>
  )
}
