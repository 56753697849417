import { errorToast } from 'app/packs/src/utils/error-toast'
import { successToast } from 'app/packs/src/utils/success-toast'
import { Comment } from 'store/modules/comments'

export class CommentVm {
  constructor(public comment: Comment) {}

  async onDelete() {
    const result = await this.comment.destroy()
    if (result?.success) {
      successToast(`Comment deleted`)
    } else {
      errorToast()
    }
  }

  get endOfThread(): boolean {
    return (
      (this.comment.topLevel &&
        (this.comment.replies?.length == 0 ||
          (this.comment.replies?.length > 0 &&
            !this.comment.lastReplyWithContent))) ||
      this.comment.parent?.lastReplyWithContent === this.comment
    )
  }
}
