export const canCopyToClipboard =
  'navigator' in window && navigator.clipboard !== undefined

export const copyToClipboard = (text: string) => {
  if (!canCopyToClipboard) return false
  try {
    navigator.clipboard.writeText(text)
    return true
  } catch (err) {
    return false
  }
}
