import * as React from 'react'
import cn from 'classnames'
import { HtmlContent } from 'components/atoms/editor/html-content'
import { User } from 'store/modules/users'
import styles from './styles.module.scss'

export type BodyProps = {
  text: string
  user: User | null
}

export const Body: React.VFC<BodyProps> = (props) => {
  const { text, user } = props

  return (
    <div className={cn(styles.body)}>
      <ChangeUser user={user} />
      <HtmlContent className="inline">{text}</HtmlContent>
    </div>
  )
}

const ChangeUser: React.VFC<{ user: User | null }> = (props) => {
  const { user } = props

  return (
    <>
      {user ? (
        <a href={user.profileUrl} className="text-gray-900">
          {user.fname}
        </a>
      ) : (
        <p className="inline mb-0 text-gray-900">[User]</p>
      )}
    </>
  )
}
