import toTitleCase from 'to-title-case'
import { ChangeItemListVm } from './change-item-list-vm'
import { ChangeOperation } from './change-item-list'
import { store } from 'store/index'

const OUTCOME_ATTRIBUTES = ['content', 'list_position']
const SKILL_ATTRIBUTES = ['description', 'editable_by', 'name']

export class SkillVariantChangeItemListVm extends ChangeItemListVm {
  get operations() {
    if (!this.renderIndividualChanges) return this.change.operations

    const attributes =
      this.change.resourceType === 'Outcome'
        ? OUTCOME_ATTRIBUTES
        : SKILL_ATTRIBUTES

    return this.change.operations.filter((operation) =>
      attributes.includes(operation.attribute)
    )
  }

  get renderIndividualChanges() {
    return (
      ['Outcome', 'Skill'].includes(this.change.resourceType) &&
      this.change.type === 'update'
    )
  }

  text(operation?: ChangeOperation) {
    switch (this.change.resourceType) {
      case 'Outcome':
        return this.outcomeText(operation)
      case 'Skill':
        return this.skillText(operation)
      case 'SkillLevel':
        return this.skillLevelText
      case 'SkillVariant':
        return this.skillVariantText
      default:
        return ''
    }
  }

  private get nameValue() {
    return this.findOperation('name', this.change.operations)?.value
  }

  private get outcomeCreateChange() {
    return store.changes.forResourceByType(
      this.change.resourceId,
      'Outcome',
      'create'
    )[0]
  }

  private get previousNameValue() {
    return this.findOperation('name', this.change.operations)?.previousValue
  }

  private get skillLevelText() {
    switch (this.change.type) {
      case 'create':
        return ` added level ${this.level()}`
      case 'destroy':
        return ` removed level ${this.previousLevel()}`
      case 'update':
        return this.discardedAtOperation
          ? ` removed ${this.levelText(
              this.level(this.skillLevelCreateChange(this.change.resourceId))
            )}`
          : ` changed the description of ${this.levelText(
              this.level(this.skillLevelCreateChange(this.change.resourceId))
            )} from "${this.previousNameValue}" to "${this.nameValue}"`
    }
  }

  private get skillVariantText() {
    switch (this.change.type) {
      case 'create':
        return ' created this Skill Variant'
      case 'destroy':
        return ' removed this Skill Variant'
      case 'update':
        return this.discardedAtOperation
          ? ' removed this Skill Variant'
          : ` changed the name from "${this.previousNameValue}" to "${this.nameValue}"`
    }
  }

  private get updateOutcomeLevel() {
    const outcomeCreateChange = this.outcomeCreateChange
    if (!outcomeCreateChange) return

    const level = this.level(outcomeCreateChange)
    if (level) return level

    const skillLevelId = this.findOperation(
      'skill_level_id',
      outcomeCreateChange.operations
    )?.value

    if (!skillLevelId) return

    return this.level(this.skillLevelCreateChange(skillLevelId))
  }

  private outcomeLevel(attribute: 'previousValue' | 'value') {
    const skillLevelId = this.findOperation(
      'skill_level_id',
      this.change.operations
    )?.[attribute]

    if (!skillLevelId) return

    return this.level(this.skillLevelCreateChange(skillLevelId))
  }

  private outcomeText(operation?: ChangeOperation) {
    switch (this.change.type) {
      case 'create':
        return ` added an example for ${this.levelText(
          this.level() || this.outcomeLevel('value')
        )}`
      case 'destroy':
        return ` removed an example for ${this.levelText(
          this.previousLevel() || this.outcomeLevel('previousValue')
        )}`
      case 'update':
        return this.discardedAtOperation
          ? ` removed an example for ${this.levelText(this.updateOutcomeLevel)}`
          : this.outcomeUpdateText(operation)
    }
  }

  private outcomeUpdateText(operation?: ChangeOperation) {
    if (!operation) return ''

    const attribute = toTitleCase(operation.attribute).toLowerCase()
    const previousValue = operation.previousValue
    const value = operation.value

    const level = this.updateOutcomeLevel
    const levelText = this.levelText(level)

    if (previousValue && value) {
      return ` changed the ${attribute} for an example for ${levelText} from "${previousValue}" to "${value}"`
    } else if (previousValue) {
      return ` removed the ${attribute} "${previousValue}" for an example for ${levelText}`
    } else {
      return ` added a ${attribute} "${value}"  for an example for ${levelText}`
    }
  }

  private skillLevelCreateChange(skillLevelId: string) {
    return store.changes.forResourceByType(
      skillLevelId,
      'SkillLevel',
      'create'
    )[0]
  }

  private skillText(operation?: ChangeOperation) {
    switch (this.change.type) {
      case 'create':
        return ' created this Skill'
      case 'destroy':
        return ' removed this Skill'
      case 'update':
        return this.skillUpdateText(operation)
    }
  }

  private skillUpdateText(operation?: ChangeOperation) {
    if (!operation) return ''

    const attribute = toTitleCase(operation.attribute).toLowerCase()

    const previousValue = this.translatedValue(
      operation.attribute,
      operation.previousValue
    )

    const value = this.translatedValue(operation.attribute, operation.value)

    if (previousValue && value) {
      return ` changed the ${attribute} from "${previousValue}" to "${value}"`
    } else if (previousValue) {
      return ` removed the ${attribute} "${previousValue}"`
    } else {
      return ` added a ${attribute} "${value}"`
    }
  }

  private translatedValue(attribute: string, value: string | null) {
    if (attribute !== 'editable_by') return value

    switch (value) {
      case '0':
        return 'Admins and editors'
      case '1':
        return 'Admins only'
      default:
        return undefined
    }
  }
}
