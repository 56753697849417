import { Avatar } from 'src/design-system'
import * as React from 'react'
import { avatarProps } from 'app/packs/src/utils/user-helpers'
import { Change } from 'store/modules/changes'
import { FormattedDateTime } from 'components/formatted-datetime'

export type RootProps = {
  change: Change
  children: React.ReactNode
}

export const Root: React.VFC<RootProps> = (props) => {
  const { change, children } = props

  return (
    <div className="flex gap-2 w-full">
      <Avatar
        {...(change.user
          ? avatarProps(change.user)
          : {
              alt: 'Avatar for [user]',
              fallbackText: 'U',
              isInactive: false,
              src: null,
            })}
        size="xs"
      />
      <div className="flex flex-col">
        {children}
        <FormattedDateTime
          className="text-gray-600 text-xs"
          date={change.createdAt}
        />
      </div>
    </div>
  )
}
