import * as React from 'react'
import cn from 'classnames'
import { GlobalModal } from 'src/design-system'
import { SidebarSimple, X } from '@phosphor-icons/react'
import { useMegaModal } from './root'

export type NavProps = {
  children: React.ReactNode
  className?: string
}

export const Nav: React.VFC<NavProps> = (props) => {
  const { children, className = true } = props

  return (
    <header
      className={cn(
        'border-0 border-b border-gray-100 border-solid flex gap-3 h-12 items-center min-h-[48px] px-4 absolute top-0 inset-x-0 bg-white z-30',
        className
      )}
    >
      {children}
    </header>
  )
}

export const CloseButton = () => (
  <GlobalModal.CloseButton className="flex items-center rounded-sm !outline-none focus:border focus:border-solid focus:!border-theme-60">
    <X weight="bold" className="text-gray-900 w-4 h-4" />
    <span className="sr-only">Close</span>
  </GlobalModal.CloseButton>
)

export const RightPaneButton = () => {
  const { toggleRightPane } = useMegaModal()
  return (
    <button onClick={toggleRightPane}>
      <SidebarSimple
        className="-scale-100 text-gray-300 w-5 h-5"
        weight="bold"
      />
    </button>
  )
}
