import { AnimatePresence, motion } from 'framer-motion'
import * as React from 'react'
import { useMounted, Button, InputGroup } from 'src/design-system'
import { Editor, EditorContext, EditorProps } from 'components/atoms/editor'
import { Comment } from 'store/modules/comments'
import { store } from 'store/index'
import { Position } from 'store/modules/positions'
import { ArrowRight } from '@phosphor-icons/react'
import { usersWhoCan } from 'app/packs/src/policies'
import { SkillVariant } from 'store/modules/skill-variants'

export type CommentFormProps = Omit<
  React.HTMLAttributes<HTMLFormElement>,
  'children' | 'onSubmit'
> & {
  /**
   * Hook called when the user submits the comment
   */
  onSubmit?(): void
  /**
   * Text to display in the privacy notice
   */
  privacyNotice?: React.ReactNode
  /**
   * Comment to edit if exists
   */
  comment?: Comment
  /**
   * parentId of a reply comment
   */
  parentId?: string
  /**
   * Optional hook to exit the form. Cancel button will only be shown
   * if this prop is present.
   */
  onCancel?: () => void
  /**
   * Label to show on the submit button
   * @default Add comment
   */
  submitLabel?: string
  commentable: Position | SkillVariant
}

const ErrorMessage = motion(InputGroup.ErrorMessage)

export const CommentForm: React.VFC<CommentFormProps> = ({
  onSubmit,
  comment,
  parentId = comment?.parent?.id,
  onCancel,
  submitLabel = 'Add comment',
  commentable,
  ...restProps
}) => {
  const mounted = useMounted()
  const [content, setContent] = React.useState(comment?.content || '')
  const [taggedUsers, setTaggedUsers] = React.useState<string[]>([])
  const [submitCount, setSubmitCount] = React.useState(0)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const editor = React.useRef<EditorContext>(null)
  const commentableType = commentable._type.name
  const users = usersWhoCan(commentable, 'edit')
  const { privacyNotice, ...otherProps } = restProps
  const changeHandler: EditorProps['onChange'] = (
    value,
    { users = [] } = {}
  ) => {
    setContent(value)
    setTaggedUsers(users.map((user) => user.id))
    setErrorMessage('')
  }

  const submitHandler: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    if (!content.length) return setErrorMessage('Please provide a comment')

    setErrorMessage('')
    setLoading(true)
    try {
      // const error = await onSubmit?.(content, parentId, taggedUsers)
      const result = comment
        ? await store.comments.update(comment.id, {
            content,
            taggedUsers,
          })
        : await store.comments.create(
            {
              content,
              taggedUsers,
              parent: parentId,
              commentableId: commentable.id,
              commentableType,
            },
            { include: ['commentable'] }
          )

      if (!mounted.current) return
      if (!result.success) {
        setErrorMessage(
          result.message ?? 'Something went wrong, please try again'
        )
      } else {
        setContent('')
        onSubmit?.()
      }
      setSubmitCount(submitCount + 1)
    } catch {
      setErrorMessage('Something went wrong, please try again')
    } finally {
      if (mounted.current) setLoading(false)
    }
  }

  return (
    <form
      {...otherProps}
      onSubmit={submitHandler}
      role="region"
      aria-label="Comment form"
    >
      <InputGroup.Root>
        <Editor
          initialContent={comment?.content ?? ''}
          autoFocus
          key={submitCount}
          invalid={!!errorMessage}
          ref={editor}
          onChange={changeHandler}
          name="comment"
          rows={2}
          placeholder={`Add a comment...`}
          className="p-4 -mx-2 mb-4 pb-16 border border-gray-200"
          aria-label="Add a comment..."
          aria-describedby={errorMessage ? 'comment-form-error' : undefined}
          taggableUsers={users}
        />
        <div className="flex mt-2 items-center justify-end">
          <div className="flex -mt-24 -ml-4 items-center">
            {onCancel && (
              <Button variant="outline" onClick={onCancel} className="ml-4">
                Cancel
              </Button>
            )}
            <Button
              disabled={loading}
              type="submit"
              aria-label={submitLabel}
              className="ml-2 mr-2 rounded-full"
              data-testid="add-comment-button"
            >
              <ArrowRight size={14} />
            </Button>
          </div>
        </div>
        <AnimatePresence>
          {errorMessage.length && (
            <ErrorMessage
              id="comment-form-error"
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              {errorMessage}
            </ErrorMessage>
          )}
        </AnimatePresence>
      </InputGroup.Root>
    </form>
  )
}
