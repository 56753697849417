import * as React from 'react'
import cn from 'classnames'
import { useMegaModal } from './root'

export type RightPaneProps = {
  children: React.ReactNode
  className?: string
}

export const RightPane: React.VFC<RightPaneProps> = (props) => {
  const { children, className } = props
  const { rightPaneOpen } = useMegaModal()

  return (
    <div
      className={cn(
        'absolute break-words mx-0 p-8 right-0 top-12 bottom-0 overflow-y-auto w-screen sm:w-[380px] bg-gray-25 z-sideNav transition-transform duration-300',
        rightPaneOpen ? 'translate-x-0' : 'translate-x-full',
        className
      )}
    >
      {children}
    </div>
  )
}
